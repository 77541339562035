import * as THREE from './node_modules/three/build/three.js';
import {WEBGL} from './node_modules/three/examples/jsm/WebGL';

import gsap from 'gsap';
import $ from "jquery";

import {Power1, Power2, Power3, Power4, Elastic, TweenMax, Sine} from 'gsap';

import SplitText from "./gsap_bonus/SplitText.min.js";

import CameraControls from './node_modules/camera-controls/dist/camera-controls.js';
import { GLTFLoader } from './libs/dependencies/GLTFLoader.js';

var obj3D = require('./assets/Binar animation.gltf');

//var background = require('./assets/oneside_maps/background.png');

var mapa1 = require('./assets/oneside_maps/1-opt.jpg');
var mapa2 = require('./assets/oneside_maps/2-opt.jpg');
var mapaFlat = require('./assets/oneside_maps/2-back-opt.jpg');

var alphaMap = require('./assets/oneside_maps/Mask-opt.png');
var alphaMapPlane = require('./assets/oneside_maps/Single-mask-2-opt.png');

var snow1 = require('./assets/oneside_maps/1-opt.png');

//var videoSrc = require("./assets/oneside_maps/motion.mp4");

var fallBackSrc = require("./assets/mp4/fallback.mp4");

//var videoSrcWebM = require("./assets/oneside_maps/motion.webm");

// var spriteAnimation = require('./assets/oneside_maps/texture.png');

CameraControls.install( { THREE: THREE } );

var camera, scene, renderer, cameraControls, clock, gui, objectGroup, objectGroupIns, stats;
var parameters, materials = [];

var wishes = ["fr", "en", "de", "es", "it", "cht", "chs"];
var selectedLang = 0;

var PLANE_WIDTH = 2.5;
			var PLANE_HEIGHT = 2.5;
			var CAMERA_HEIGHT = 1;

var state = {
    shadow: {
        blur: 3.5,
        darkness: 1,
        opacity: 1,
    },
    plane: {
        color: '#ffffff',
        opacity: 1,
    },
    showWireframe: false,
};

var mixer,
mixer2,
mixer3;
     
var cardFront, cardBack, cardBackFlat;

var positionObj = new THREE.Vector3(0, 10, 0);
var rotationZ = 0;

var windowHalfX = window.innerWidth / 2;
var windowHalfY = window.innerHeight / 2;

var spriteMixer, actionSprite;

var light, light2, light3, light4, lightGroup;

var mouse = new THREE.Vector2();

var animationInProgress = false;

var actions = {};

var fallbackActive = false;

$(function() {

   fallbackActive = !WEBGL.isWebGLAvailable();

    if(navigator.userAgent.indexOf('MSIE')!==-1 || navigator.appVersion.indexOf('Trident/') > -1){
        fallbackActive = true;
    }

    if ( !fallbackActive ) {  
        
        // var video = document.getElementById( 'videoFront' );

        // var webmSupport = "" !== video.canPlayType( 'video/webm; codecs="vp8, vorbis"' )

        // var vidSrc = webmSupport ? videoSrcWebM : videoSrc;

        // var req = new XMLHttpRequest();
        // req.open('GET', vidSrc, true);
        // req.responseType = 'blob';

        // req.onload = function() {
        //     // Onload is triggered even on 404
        //     // so we need to check the status code
        //     if (this.status === 200) {
        //         var videoBlob = this.response;
        //         var vid = URL.createObjectURL(videoBlob); // IE10+
        //         // Video is now downloaded
        //         // and we can set it as source on the video element

        //         video.src = vid;

        //         //video.play();
        //     }
        // }

        // req.onerror = function() {
        // // Error
        // }

        // req.send();

        prealodTextures(
            [
                snow1, 
                // spriteAnimation, 
                mapa1, 
                mapa2, 
                mapaFlat, 
                alphaMap, 
                alphaMapPlane
            ]
        )

        // init()
        // animate();

        $(".video-fallback").remove();
   }else{

        // var video = document.getElementById( 'video-fallback' );
        // video.src = fallBackSrc;
        
        gsap.set('.video-fallback', {display: 'block'})
        gsap.to('.preloader', {autoAlpha: 0, ease: 'power3.out', duration: 0.7});

        states = [
            "closed",
            "opened",
            "flat"
        ]
    
        cardState = states[0];

        initFallBackVideo();
   }
    
});

function initFallBackVideo(){
    console.log("%c 📈: initFallBackVideo -> initFallBackVideo ", "font-size:16px;background-color:#b40e92;color:white;", fallbackActive)
    
    var isVideoStarted = false;
    var lastVideoTime = -1;
    var cb = function (param1) {console.log('callback', param1)};

    var callbackMaps = [
        {time: 4, callback: openCard},
        {time: 8.5, callback: flatCard},
        {time: 15.5, callback: getBackToCard}
    ]

    $('#video-fallback').on('play', function (e){
        if (isVideoStarted == false) {
            isVideoStarted = true;
            startWebsite(1);
            // console.log("%c 🌄: play ", "font-size:16px;background-color:#7e6af5;color:white;", e)
        }
    })


    $('#video-fallback').on('pause', function (e){
        
        console.log("%c 🌄: pause ", "font-size:16px;background-color:#7e6af5;color:white;", e)
        
    })

    function videoTimeLoop (){
        var currentVideoTime = $('#video-fallback').prop('currentTime')
        var videoDuration = $('#video-fallback').prop('duration')
        
        for (let i = 0; i < callbackMaps.length; i++) {
            const element = callbackMaps[i];
            
            if(lastVideoTime < element.time && currentVideoTime > element.time){
                element.callback()
            }

        }
        // UDPATE timline porgresss

        lastVideoTime = currentVideoTime

        requestAnimationFrame(videoTimeLoop)
    }
    requestAnimationFrame(videoTimeLoop)

    var video = document.getElementById( 'video-fallback' );
    video.src = fallBackSrc;
    
    gsap.set('.video-fallback', {display: 'block'})
    gsap.to('.preloader', {autoAlpha: 0, ease: 'power3.out', duration: 0.7});

    states = [
        "closed",
        "opened",
        "flat"
    ]

    cardState = states[0];

}

var materialSettings = {};

var cardOpened = false;


// cameraControls.setPosition(0, -3, -34, true);
// cameraControls.moveTo(0, -3, 0, true);

var cameraPosition = new THREE.Vector3(0, -2, -29);
var cameraPositionOpened = new THREE.Vector3(0, -3, -35);
var cameraPositionFlat = new THREE.Vector3(0, -3, -40);

var cameraTarget = new THREE.Vector3(0, -2, 2);
var cameraTargetOpened = new THREE.Vector3(0, -3, 0);
var cameraTargetFlat = new THREE.Vector3(0, -3, 0);

var cameraPositionTween = cameraPosition;
var cameraTargetTween = cameraTarget;

var cardRollOver = false;

var tapTime = 0;

var cardReadyToInteraction = false;

var animations = [];

var states = [];

var cardState;

var fogColor = new THREE.Color(0xd3dbe0);
var fogColorWater = new THREE.Color(0x5c656e);
var groundColor = new THREE.Color(0x6a7680);

var sphereColor = new THREE.Color(0xb8c4cc);
var sphereColorDark = new THREE.Color(0x475158);

var sphereColorTween = new THREE.Color(0xb8c4cc);

var fogColorTween = new THREE.Color(0xd3dbe0);

var textureLoaderMap;

var groundMaterial, groundMesh, frontChild, flatTexture, flatTexture1, backChild, backChildFlat;

var sphere; 

var particlesGroup, particlesFalling = true;

var map1Texture, map2Texture;

var planeFront;

var delayedCallWishes, backgroundScene, backgroundCamera;

function prealodTextures (images){
    var elements = []
    var imagesCount = images.length
    var imagesLoaded = 0

    function onLoad(){
        imagesLoaded++
        //console.log("%c 🐝: onLoad -> imagesCount / imagesLoaded ", "font-size:16px;background-color:#40bb97;color:white;", imagesCount, imagesLoaded)
        if(imagesCount == imagesLoaded){
        
            gsap.to('.preloader', {autoAlpha: 0, ease: 'power3.out', duration: 0.7, onComplete: function (){
                
            }})
            init()
            animate();
            
        }
    }
    
    for (let i = 0; i < images.length; i++) {
        
        var img = document.createElement('img')
        elements.push(img)
            img.onload = onLoad
            img.src = images[i]
    }

}

function init2() {

    var fogNear = 70;
    var fogFar = 90;

    clock = new THREE.Clock();

    camera = new THREE.PerspectiveCamera( 50, window.innerWidth / window.innerHeight, 1, 150 );
    camera.position.set( cameraPosition.x, cameraPosition.y, cameraPosition.z );

    scene = new THREE.Scene();
    //scene.background = new THREE.Color( fogColor );
    //scene.fog = new THREE.Fog( fogColor, fogNear, fogFar );

    var loader = new GLTFLoader();

    loader.load( obj3D, function ( object ) {

        var objectScene = object.scene;

        objectScene.position.x = 0;
        objectScene.position.y = -10;
        objectScene.position.z = 0;

        scene.add(objectScene)
    } );

    renderer = new THREE.WebGLRenderer( { antialias: false, logarithmicDepthBuffer:false } );
    renderer.setSize( window.innerWidth, window.innerHeight );
    renderer.setPixelRatio( window.devicePixelRatio );
    renderer.setClearColor(fogColor);

    cameraControls = new CameraControls( camera, renderer.domElement );
    cameraControls.minDistance = 20;
    cameraControls.maxDistance = 70;
    cameraControls.maxPolarAngle = 100 * Math.PI/180
    cameraControls.minPolarAngle = 65 * Math.PI/180
    cameraControls.dollySpeed = 1;
    // cameraControls.enabled = false;

    cameraControls.setBoundary(new THREE.Box3(new THREE.Vector3(-16,-3,-16), new THREE.Vector3(16,-2,16)));
    cameraControls.mouseButtons.left = CameraControls.ACTION.ROTATE;
    cameraControls.mouseButtons.middle = CameraControls.ACTION.DOLLY;
    // cameraControls.mouseButtons.right = CameraControls.ACTION.ROTATE;
    cameraControls.mouseButtons.wheel = CameraControls.ACTION.DOLLY;
    cameraControls.touches.one = CameraControls.ACTION.TOUCH_ROTATE;
    // cameraControls.touches.two = CameraControls.ACTION.TOUCH_DOLLY;
    cameraControls.touches.three = CameraControls.ACTION.TRUCK;


    cameraControls.setLookAt( camera.position.x, camera.position.y, camera.position.z, cameraTarget.x, cameraTarget.y, cameraTarget.z, false );

    document.body.appendChild( renderer.domElement );

}

function init() {
    //console.log("%c 🏨: init -> init ", "font-size:16px;background-color:#bc39b0;color:white;")
    
    materialSettings.alphaTest = 0.01;
    materialSettings.roughness = 10;
    materialSettings.shininess = 0;
    materialSettings.metalness = 0;

    states = [
        "closed",
        "opened",
        "flat"
    ]

    cardState = states[0];

    animations = [
        {   
            name: "start",
            from: 0,
            to: 0.050
        },
        {   
            name: "rollover",
            from: 0.050,
            to: 0.15
        },
        {   
            name: "open",
            from: 0.15,
            to: 1.55
        },
        {   
            name: "flat",
            from: 1.55,
            to: 2.05 //3.05
        }
    ]
    // stats = new Stats();
    // stats.dom.style.bottom = "0px";
    // stats.dom.style.top = "auto";

    // document.body.appendChild( stats.dom );
                
    var fogNear = 70;
    var fogFar = 90;

    clock = new THREE.Clock();
    camera = new THREE.PerspectiveCamera( 50, window.innerWidth / window.innerHeight, 1, 150 );
    camera.position.set( cameraPosition.x, cameraPosition.y, cameraPosition.z );
    //camera.target.position.set(0,0,1);

    scene = new THREE.Scene();
    //scene.background = new THREE.Color( fogColor );
    //scene.fog = new THREE.Fog( fogColor, fogNear, fogFar );

    var lightAmbient = new THREE.AmbientLight( 0xFFFFFF, 0.95 ); // soft white light
    scene.add( lightAmbient );

    lightGroup = new THREE.Group();

    lightGroup.position.set( 0, 0, 0 );

    light = new THREE.PointLight( 0xffffff, 0.1, 200 );
    light.position.set( -10, 5, -30 );

    light2 = new THREE.PointLight( 0xffffff, 0.05, 200 );
    light2.position.set( -20, 5, 30 );

    light3 = new THREE.PointLight( 0xffffff, 0.1, 200 );
    light3.position.set( -30, 5, 20 );

    light4 = new THREE.PointLight( 0xffffff, 0.05, 200 );
    light4.position.set( 30, 5, 20 );

    var spotLightHelper = new THREE.PointLightHelper( light,  1, 0xFF00FF);
   //lightGroup.add( spotLightHelper );

    lightGroup.add( light );
    lightGroup.add( light2 );
    lightGroup.add( light3 );
    lightGroup.add( light4 );

    var spotLightHelper2 = new THREE.PointLightHelper( light2,  1, 0xFF0000);
    //lightGroup.add( spotLightHelper2 );

    var spotLightHelper3 = new THREE.PointLightHelper( light3,  1, 0xF00000);
    //lightGroup.add( spotLightHelper3 );
    var spotLightHelper4 = new THREE.PointLightHelper( light4,  1, 0x00000F);
    //lightGroup.add( spotLightHelper4 );

    var loader = new GLTFLoader();

    textureLoaderMap = new THREE.TextureLoader();
    map1Texture = textureLoaderMap.load(mapa1);
    map2Texture = textureLoaderMap.load(mapa2); 

    var alphaTexture = textureLoaderMap.load(alphaMap);
    
    var alphaTexturePlane = textureLoaderMap.load(alphaMapPlane);

    flatTexture = textureLoaderMap.load(mapaFlat);
    
    //flatTexture1 = textureLoaderMap.load(mapaFlat1);

    makeGround();

    map1Texture.flipY = false;
    map2Texture.flipY = false;
    alphaTexture.flipY = false;
    flatTexture.flipY = false;
    //flatTexture1.flipY = false;

    //alphaTexturePlane.flipX = false;

    renderer = new THREE.WebGLRenderer( { antialias: false, logarithmicDepthBuffer:true } );
    renderer.setSize( window.innerWidth, window.innerHeight );
    renderer.setPixelRatio( window.devicePixelRatio );
    renderer.setClearColor(fogColor);

    var maxAnisotropy = renderer.capabilities.getMaxAnisotropy();
    
   //map1Texture.anisotropy = maxAnisotropy;
    map2Texture.anisotropy = maxAnisotropy;
    //alphaTexture.anisotropy = maxAnisotropy;
    //flatTexture.anisotropy = maxAnisotropy;

    var video = document.getElementById( 'videoFront' );

    var textureVideo = new THREE.VideoTexture( video );

    textureVideo.center.set( 0.5, 0.5 );
    textureVideo.repeat.set( - 1, 1 );

    var geometryPlane = new THREE.PlaneGeometry(10, 20, 1 );
    var materialPlane = new THREE.MeshStandardMaterial( {color: 0xffffff, side: THREE.BackSide} );

    materialPlane.alphaMap = alphaTexturePlane;
    materialPlane.alphaTest = materialSettings.alphaTest;
    materialPlane.map = textureVideo;
    materialPlane.roughness = materialSettings.roughness;
    materialPlane.metalness = materialSettings.metalness;
    materialPlane.needsUpdate = true;

    planeFront = new THREE.Mesh( geometryPlane, materialPlane );
    planeFront.position.y = 0;
    planeFront.position.x = -0.1;

    objectGroup = new THREE.Group();

    objectGroupIns = new THREE.Group();

    objectGroup.add(lightGroup);

    document.addEventListener( 'mousemove', onDocumentMouseMove, false );

    loader.load( obj3D, function ( object ) {

        var objectScene = object.scene;

        mixer = new THREE.AnimationMixer( object.scene );

        var action = mixer.clipAction(object.animations[0]); 
        action.play(); 
        action.paused = true;

        cardFront = object.scene;

        objectScene.traverse( function ( child ) {

            if ( child.isMesh ) {

                child.castShadow = true;
                child.receiveShadow = true;

                if (child.name == "Side_A"){

                    frontChild = child;
                    
                    //child.material.color = new THREE.Color(0xFF0000);
                    child.material.map = map1Texture;

                    child.material.alphaMap = alphaTexture;
                    child.material.alphaTest = materialSettings.alphaTest;
                    child.material.roughness = materialSettings.roughness;

                    child.material.metalness = materialSettings.metalness;

                    child.material.side = THREE.BackSide;

                    child.material.needsUpdate = true;
                }
            }

        } );

        objectScene.position.x = 0;
        objectScene.position.y = -10;
        objectScene.position.z = 0;

        objectGroupIns.add( objectScene );

        objectGroupIns.add(planeFront);
        

        objectGroup.add(objectGroupIns)
    } );

    var materialMesh2 = new THREE.MeshStandardMaterial( { color: 0xFFFFFF, skinning: true } );

    loader.load( obj3D, function ( object2 ) {

        var objectScene2 = object2.scene;

        mixer2 = new THREE.AnimationMixer( object2.scene );

        var action = mixer2.clipAction(object2.animations[0]); 
        action.play(); 
        action.paused = true;

        cardBack = object2.scene;
        
        console.log("ALA MA KOTA!!");

        objectScene2.traverse( function ( child ) {

            child.castShadow = true;
            child.receiveShadow = true;

            
            if ( child.isMesh ) {

                if (child.name == "Side_A"){

                    
                    backChild = child;
                    child.material.map = map2Texture;

                    //child.material.color = new THREE.Color(0xFF00FF);

                    child.material.alphaMap = alphaTexture;
                    child.material.alphaTest = materialSettings.alphaTest;
                    child.material.roughness = materialSettings.roughness;
                    child.material.metalness = materialSettings.metalness;
                    child.material.side = THREE.FrontSide;
                    child.material.needsUpdate = true;

                }
            }

        } );

        objectScene2.position.x = 0;
        objectScene2.position.y = -10;
        objectScene2.position.z = 0;

        objectGroupIns.add( objectScene2 );
        objectGroupIns.position.y = -30;

        startWebsite(1.5);
    } );

    cameraControls = new CameraControls( camera, renderer.domElement );
    cameraControls.minDistance = 20;
    cameraControls.maxDistance = 70;
    cameraControls.maxPolarAngle = 100 * Math.PI/180
    cameraControls.minPolarAngle = 65 * Math.PI/180
    cameraControls.dollySpeed = 1;
    cameraControls.enabled = false;

    cameraControls.setBoundary(new THREE.Box3(new THREE.Vector3(-16,-3,-16), new THREE.Vector3(16,-2,16)));
    cameraControls.mouseButtons.left = CameraControls.ACTION.ROTATE;
    cameraControls.mouseButtons.middle = CameraControls.ACTION.DOLLY;
    // cameraControls.mouseButtons.right = CameraControls.ACTION.ROTATE;
    cameraControls.mouseButtons.wheel = CameraControls.ACTION.DOLLY;
    cameraControls.touches.one = CameraControls.ACTION.TOUCH_ROTATE;
    // cameraControls.touches.two = CameraControls.ACTION.TOUCH_DOLLY;
    cameraControls.touches.three = CameraControls.ACTION.TRUCK;


    cameraControls.setLookAt( camera.position.x, camera.position.y, camera.position.z, cameraTarget.x, cameraTarget.y, cameraTarget.z, false );

    document.body.appendChild( renderer.domElement );

    var geometry = new THREE.BufferGeometry();
    var vertices = [];

    var textureLoader = new THREE.TextureLoader();

    var snowflakeTexture1 = textureLoader.load(snow1);
    // var snowflakeTexture2 = textureLoader.load(snow2);
    // var snowflakeTexture3 = textureLoader.load(snow3);
    // var snowflakeTexture4 = textureLoader.load(snow4);

    // DO PRZEMYŚLENIA TE PARTICLE //
    // for ( var i = 0; i < 1; i ++ ) {

        //var x = Math.random() * 1000 - 500;
        //var y = Math.random() * 1000 - 500;
        //var z = Math.random() * 1000 - 500;

        vertices.push( 0, 0, 0 );

    // }

    geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( vertices, 3 ) );

    parameters = [
        [[ 1.0, 0.2, 0.5 ], snowflakeTexture1, 1],
        [[ 0.95, 0.1, 0.5 ], snowflakeTexture1, 1],
        [[ 0.90, 0.05, 0.5 ], snowflakeTexture1, 1],
        [[ 0.85, 0, 0.5 ], snowflakeTexture1, 1]
    ];

    particlesGroup = new THREE.Group();

    for ( var i = 0; i < 200; i ++ ) {

        var color = parameters[ i%4 ][ 0 ];
        var sprite = parameters[ i%4 ][ 1 ];
        var size = parameters[ 0 ][ 2 ];

        materials[ i ] = new THREE.PointsMaterial( { size: size, sizeAttenuation:true, map: sprite, blending: THREE.NormalBlending, depthTest: true, transparent: true } );
        //materials[ i ].color.setHSL( color[ 0 ], color[ 1 ], color[ 2 ] );

        var particles = new THREE.Points( geometry, materials[ i ] );

        particles.velocity = new THREE.Vector3(Math.random() * 10 - 5, -Math.random() * 2 - (4), Math.random() * 10 - 5);
        particles.rotation.x = Math.random() * 6;
        particles.rotation.y = Math.random() * 6;
        particles.rotation.z = Math.random() * 6;

        particles.animation = Math.random() * 10;
        particles.position.x = Math.random() * 120 - 60;
        particles.position.y = Math.random() * 120 - 60;
        particles.position.z = Math.random() * 120 - 60;

        particlesGroup.add( particles );

    }

    scene.add( particlesGroup );

    renderer.render( scene, camera );

    window.addEventListener( 'resize', onWindowResize, false );

    onWindowResize();
    //makeShadow();
}
 

function makeGround(){

    var geometry = new THREE.SphereGeometry( 180, 12, 12);//, Math.PI, Math.PI );
    var material = new THREE.MeshBasicMaterial( {color: sphereColor} );
    material.side = THREE.BackSide;

    sphere = new THREE.Mesh( geometry, material );

    // Load the background texture
    // var texture = THREE.ImageUtils.loadTexture( background   );
    // var backgroundMesh = new THREE.Mesh(
    //     new THREE.PlaneGeometry(2, 2, 0),
    //     new THREE.MeshBasicMaterial({
    //         map: texture
    //     }));

    // backgroundMesh .material.depthTest = false;
    // backgroundMesh .material.depthWrite = false;

    // Create your background scene
    backgroundScene = new THREE.Scene();

    var fogNear = 80;
    var fogFar = 160;

    backgroundScene.fog = new THREE.Fog( fogColor, fogNear, fogFar )

    backgroundCamera = new THREE.PerspectiveCamera( 50, window.innerWidth / window.innerHeight, 1, 150 );
    backgroundCamera.position.set( cameraPosition.x, cameraPosition.y, cameraPosition.z );


    backgroundScene .add(backgroundCamera );
    backgroundScene .add(sphere );

    // scene.add( sphere );
}

function onDocumentMouseMove( event ) {
    event.preventDefault();

    mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
    mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;

    if ((cardState == "flat" || cardReadyToInteraction == true) && cardState != "openedAgain"){
        cameraControls.rotateTo((-mouse.x) / 4 + Math.PI, (mouse.y / 4) + Math.PI/2 + (10 * Math.PI/180), true);
    }
}

function flatCard(e){
    // cameraControls.normalizeRotations();

    cardState = "flat";

    var openCardDelay = 0;
    var openCardDuration = 1.5;

    if (!fallbackActive){
        var openCardDurationColor = 1.5;
        openCardDelay = 0;

        //console.log("cameraControls.azimuthAngle", cameraControls.azimuthAngle, 90 * Math.PI/180, (180 + 90) * Math.PI/180);

        if (cameraControls.azimuthAngle < 90 * Math.PI/180 || cameraControls.azimuthAngle > (180 + 90) * Math.PI/180){
            openCardDelay = 0.4;
        }

        openCardDuration = 1.5;
        
        animationInProgress = true;

        planeFront.visible = false;

        //document.getElementById( 'videoFront' ).pause();

        // cameraControls.maxAzimuthAngle = (180 + 90 - 60) * Math.PI/180
        // cameraControls.minAzimuthAngle = (90 + 50) * Math.PI/180
        
        gsap.to(objectGroup.rotation, {y: (-150) * Math.PI/180, duration: openCardDuration * 1.5, ease:Power1.easeInOut, overwrite:true});
        gsap.to(objectGroupIns.rotation, {z:0.02, y:0, duration: openCardDuration * 2, ease:Power1.easeInOut, overwrite:true});

        gsap.to(objectGroup.position, {x: 16.2, duration: openCardDuration * 1.5, ease:Power1.easeInOut, overwrite:true});

        gsap.to(mixer._actions[0], {duration:openCardDuration * 1.5, time:animations[3].to, delay:0, ease:Power1.easeInOut, overwrite:true});
        gsap.to(mixer2._actions[0], {duration:openCardDuration * 1.5, time:animations[3].to, delay:0, ease:Power1.easeInOut, overwrite:true});
        // gsap.fromTo(mixer3._actions[0], {time:animations[3].from}, {duration:openCardDuration * 1.5, time:animations[3].to, delay:0, ease:Power4.easeOut, overwrite:true});
        
        // TO REMOVE //

        gsap.delayedCall(openCardDelay, function(){
            frontChild.material.map = flatTexture
            frontChild.material.side = THREE.FrontSide;
            frontChild.material.opacity = 0;
            frontChild.material.transparent = true;
            frontChild.material.needsUpdate = true; 
            
        });

        gsap.to(frontChild.material, {duration: openCardDurationColor*0.6, delay:openCardDelay, opacity:1, ease:Power1.easeInOut, overwrite:true, onComplete:function(){
            frontChild.material.transparent = false;
        }})
        
        backChild.material.transparent = true;

        gsap.to(backChild.material, {duration: openCardDurationColor*0.6, delay:openCardDelay, opacity:0, ease:Power1.easeInOut, overwrite:true, onComplete:function(){
            backChild.material.side = THREE.BackSide;
            backChild.material.transparent = false;
            animationInProgress = false;

        }})

        gsap.to(fogColorTween, {duration:openCardDurationColor * 1, delay:openCardDelay, r:fogColorWater.r, g:fogColorWater.g, b:fogColorWater.b, ease:Power1.easeOut, onUpdate:function(){backgroundScene.fog.color = fogColorTween; renderer.setClearColor(fogColorTween);}});
        gsap.to(sphereColorTween, {duration:openCardDurationColor * 1, delay:openCardDelay, r:sphereColorDark.r, g:sphereColorDark.g, b:sphereColorDark.b, ease:Power1.easeOut, onUpdate:function(){sphere.material.color = sphereColorTween}});
    
        for ( var i = 0; i < 200; i ++ ) {
            gsap.to(materials[ i ], {duration: openCardDuration/2, opacity:0, ease:Power1.easeIn, overwrite:true, onComplete:function (){particlesFalling = false;}});
        }
    }

    gsap.to("#logoRed", {duration:openCardDuration, delay:openCardDelay, opacity:0, ease:Power1.easeOut})
    gsap.to("#logoWhite", {duration:openCardDuration, delay:openCardDelay, opacity:1, ease:Power1.easeIn})
    gsap.to(".navigation_hints", {duration:openCardDuration / 4, autoAlpha:0, ease:Power1.easeIn});

    changeWaterText("BACK TO CARD", "#DFE5E9");
}

function changeWaterText(text, color){

    var time = 2;

    gsap.to(".card_exp", {duration:time/2, opacity:0, ease:Power1.easeOut, onComplete:function(){$(".card_exp").text(text);}})

    gsap.to(".card_exp", {duration:time, color:color, delay:time/2, opacity:1, ease:Power1.easeIn})

    gsap.to(".footer", {duration:time, color:color, delay:0, opacity:1, ease:Power1.easeIn});
    
    gsap.to(".wishes", {duration:time, color:color, delay:0, opacity:1, ease:Power1.easeIn});

    // gsap.to(".icon_description", {duration:time, color:color, delay:0, opacity:1, ease:Power1.easeIn});

    gsap.to(".link", {duration:time, color:color, delay:0, ease:Power1.easeIn});
}

function getBackToCard(){
    cardState = "openedAgain";
    
    var openCardDuration = 3;

    if (!fallbackActive){
        cameraControls.normalizeRotations();

        openCardDuration = 3;

        animationInProgress = true;
        
        planeFront.visible = true;

        //document.getElementById( 'videoFront' ).play();

        cameraControls.maxAzimuthAngle = Infinity;
        cameraControls.minAzimuthAngle = - Infinity;

        gsap.to(objectGroup.rotation, {y: (-140) * Math.PI/180, duration: openCardDuration* 1, ease:Power1.easeInOut, overwrite:true});

        gsap.to(objectGroup.position, {x: 14, duration: openCardDuration* 1, ease:Power1.easeInOut, overwrite:true});

        gsap.to(mixer._actions[0], {duration:openCardDuration * 1, time:animations[3].from, delay:0, ease:Power1.easeInOut, overwrite:true});
        gsap.to(mixer2._actions[0], {duration:openCardDuration * 1, time:animations[3].from, delay:0, ease:Power1.easeInOut, overwrite:true});

        gsap.to(fogColorTween, {duration:openCardDuration * 1.5, r:fogColor.r, g:fogColor.g, b:fogColor.b, ease:Power1.easeOut, onUpdate:function(){backgroundScene.fog.color = fogColorTween; renderer.setClearColor(fogColorTween);}});

        gsap.to(sphereColorTween, {duration:openCardDuration * 1.5, r:sphereColor.r, g:sphereColor.g, b:sphereColor.b, ease:Power1.easeOut, onUpdate:function(){sphere.material.color = sphereColorTween}});

        backChild.material.side = THREE.FrontSide;
        backChild.material.transparent = false;
        backChild.material.opacity = 1;
        frontChild.material.transparent = true;

        backChild.material.needsUpdate = true;

        gsap.to(frontChild.material, {duration: openCardDuration / 2, opacity:0, ease:Power1.easeIn, overwrite:true, onComplete:function(){
            
            frontChild.material.map = map1Texture;
        
            frontChild.material.side = THREE.BackSide;
            frontChild.material.opacity = 1;
            frontChild.material.transparent = false;
            frontChild.material.needsUpdate = true;

            animationInProgress = false;
        }})

        particlesFalling = true;
        for ( var i = 0; i < 200; i ++ ) {
            gsap.to(materials[ i ], {duration: openCardDuration/2, opacity:1, ease:Power1.easeIn, overwrite:true});
        }
    }

    gsap.to("#logoRed", {duration:openCardDuration, opacity:1, ease:Power1.easeOut})
    gsap.to("#logoWhite", {duration:openCardDuration, opacity:0, ease:Power1.easeIn})

    gsap.to(".wishes_container", {delay:1, duration:openCardDuration / 4, autoAlpha:1, ease:Power1.easeIn});

    if (!fallbackActive) gsap.to(".navigation_hints", {duration:openCardDuration / 4, autoAlpha:1, ease:Power1.easeIn});

    gsap.to(".water_header", {delay:0, duration:0.2, autoAlpha:0, ease:Power1.easeOut});
    gsap.to(".water_description", {delay:0, duration:0.2, autoAlpha:0, ease:Power1.easeOut});

    changeWaterText("About pictet-water fund", "#263843");
}

function waterAction(){

    if (animationInProgress) return;
    
    if (cardState != "flat"){
        cardState = "flat";
        flatCard();
    }else{
        cardState = "opened";

        getBackToCard()
    }
}

function revealTexts(){
    var delayIn = 0;

    gsap.to("#logoRed", {delay:delayIn + 1, duration:1, autoAlpha:1, ease:Power1.easeIn});

    gsap.to(".copyrights", {delay:delayIn + 2, duration:1, autoAlpha:1, ease:Power1.easeIn})
    gsap.to(".privacy", {delay:delayIn + 3, duration:1, autoAlpha:1, ease:Power1.easeIn})
    gsap.delayedCall(delayIn + 1, wishesAnimation);

    $(".card_exp").on("click", waterAction);
}

function closeCookies(e){
    gsap.to(".cookies_container", {y:30, autoAlpha:0, duration:0.2, ease:Power4.easeOut});
}

function clickCookies(e){
    gsap.fromTo(".cookies_container", {autoAlpha:0}, {duration:0.4, autoAlpha:1, ease:Power4.easeOut});

    gsap.fromTo(".cookies_container", {y:30}, {duration:2, y:0, ease:Power4.easeOut});
}

function openCard(e){
    var openCardDuration = 2;

    if (cardState == "closed"){

        document.body.className = '';
        
        revealTexts();


        if (!fallbackActive){
            gsap.to(objectGroup.rotation, {delay:0,y: (-140) * Math.PI/180, duration: openCardDuration*2.5, ease:Power1.easeInOut, overwrite:true});

            gsap.to(objectGroup.position, {delay:0,x: 14, duration: openCardDuration*2.5, ease:Power1.easeInOut, overwrite:true});

            gsap.to(objectGroupIns.rotation, {delay:0,z: 0.08, y: 0, duration: openCardDuration*2, ease:Power1.easeInOut, overwrite:true});
        
            cameraTargetTween = cameraControls.getTarget();
            cameraPositionTween = cameraControls.getPosition();

            gsap.to(cameraTargetTween, {x: cameraTargetOpened.x, y:cameraTargetOpened.y, z:cameraTargetOpened.z, duration: openCardDuration * 1, ease:Power1.easeInOut, overwrite:true});
            gsap.to(cameraPositionTween, {x: cameraPositionOpened.x, y:cameraPositionOpened.y, z:cameraPositionOpened.z, duration: openCardDuration * 1, onUpdate: function(){cameraControls.setTarget(cameraTargetTween.x, cameraTargetTween.y, cameraTargetTween.z, false); cameraControls.setPosition(cameraPositionTween.x, cameraPositionTween.y, cameraPositionTween.z, false);}, ease:Power1.easeInOut, overwrite:true});

            var azimuthAngle = cameraControls.azimuthAngle;

            gsap.to(mixer._actions[0], {duration:openCardDuration*2.5, time:animations[2].to, delay:0, ease:Power1.easeInOut, overwrite:true});
            gsap.to(mixer2._actions[0], {duration:openCardDuration*2.5, time:animations[2].to, delay:0, ease:Power1.easeInOut, overwrite:true});

            cardOpened = !cardOpened;

            cardState = "opened";

        }

    }else{
        
        waterAction();
    }
}

function closeCard(){

    if (cardState != "flat"){
        var openCardDuration = 1;

        overCardAnimation = false;

        $(".logo").off("click", closeCard);

        gsap.to(objectGroup.rotation, {y: (0) * Math.PI/180, duration: openCardDuration, ease:Power1.easeOut, overwrite:true});

        gsap.to(objectGroup.position, {x: 0, duration: openCardDuration * 2, ease:Power4.easeOut, overwrite:true});

        gsap.to(objectGroupIns.rotation, {z: 0, y: 0, duration: openCardDuration * 2, ease:Power4.easeOut, overwrite:true});

        cameraTargetTween = cameraControls.getTarget();
        cameraPositionTween = cameraControls.getPosition();

        gsap.to(cameraTargetTween, {x: cameraTarget.x, y:cameraTarget.y, z:cameraTarget.z, duration: openCardDuration, ease:Power4.easeOut, overwrite:true});
        gsap.to(cameraPositionTween, {x: cameraPosition.x, y:cameraPosition.y, z:cameraPosition.z, duration: openCardDuration, onUpdate: function(){cameraControls.setTarget(cameraTargetTween.x, cameraTargetTween.y, cameraTargetTween.z, false); cameraControls.setPosition(cameraPositionTween.x, cameraPositionTween.y, cameraPositionTween.z, false);}, ease:Power4.easeOut, overwrite:true});

        gsap.to(mixer._actions[0], {duration:openCardDuration/1, time:animations[2].from, delay:0, ease:Power1.easeOut, overwrite:true});
        gsap.to(mixer2._actions[0], {duration:openCardDuration/1, time:animations[2].from, delay:0, ease:Power1.easeOut, overwrite:true});

        cardOpened = !cardOpened;

        cardState = states[0];


        gsap.to("#logoRed", {duration:1, autoAlpha:0, ease:Power1.easeIn, overwrite:true});

        gsap.to(".copyrights", {duration:1, autoAlpha:0, ease:Power1.easeIn, overwrite:true});
        gsap.to(".privacy", {duration:1, autoAlpha:0, ease:Power1.easeIn, overwrite:true});
        // gsap.to(".card_exp", {delay:delayIn + 2.5, duration:1, autoAlpha:1, ease:Power1.easeIn})

        delayedCallWishes.kill();
        
        gsap.to(".wishes", {autoAlpha:0, duration:1, ease:Power1.easeIn, overwrite:true});
    }else{
        // cardState = "opened";
        getBackToCard()
    }
}

function onWindowResize() {

    windowHalfX = window.innerWidth / 2;
    windowHalfY = window.innerHeight / 2;

    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    if (window.innerWidth < 768){
        //cameraControls.touches.one = CameraControls.ACTION.TOUCH_ROTATE;
        //cameraControls.touches.two = CameraControls.ACTION.TRUCK;
        
        cameraPositionOpened = new THREE.Vector3(13, -3, -35);
        cameraPositionFlat = new THREE.Vector3(16, -3, -40);

        cameraTargetOpened = new THREE.Vector3(13, -3, 0);
        cameraTargetFlat = new THREE.Vector3(16, -3, 0);

    }else{
        //cameraControls.touches.one = CameraControls.ACTION.TOUCH_ROTATE;

        cameraPositionOpened = new THREE.Vector3(0, -3, -35);
        cameraPositionFlat = new THREE.Vector3(0, -3, -40);

        cameraTargetOpened = new THREE.Vector3(0, -3, 0);
        cameraTargetFlat = new THREE.Vector3(0, -3, 0);
    }

    renderer.setSize( window.innerWidth, window.innerHeight );
}

function wishesAnimation(){
    var language = wishes[selectedLang];

    gsap.to(".wishes", {autoAlpha:0, duration:0.5});

    var wishes_line1 = $("#wishes_"+language+" .line1");
    var wishes_line2 = $("#wishes_"+language+" .line2");

    gsap.to("#wishes_en", {autoAlpha:0});
    gsap.to("#wishes_fr", {autoAlpha:0});
    gsap.to("#wishes_de", {autoAlpha:0});
    gsap.to("#wishes_es", {autoAlpha:0});
    gsap.to("#wishes_it", {autoAlpha:0});
    gsap.to("#wishes_cht", {autoAlpha:0});
    gsap.to("#wishes_chs", {autoAlpha:0});

    gsap.to("#wishes_"+language, {autoAlpha:1, overwrite:true});

    var tl = gsap.timeline();

    var wishes_line1 = new SplitText(wishes_line1, {type:"words,chars"});
    var wishes_line2 = new SplitText(wishes_line2, {type:"words,chars"});

    TweenMax.staggerFrom(wishes_line1.chars, 1, {x:20, scaleX:0.5, autoAlpha:0, delay:0.5}, 0.05);
    TweenMax.staggerFrom(wishes_line2.chars, 1, {x:-20, scaleX:0.5, autoAlpha:0, delay:0.75}, 0.05);

    gsap.to(".wishes", {autoAlpha:1, duration:0, delay:.5});

    delayedCallWishes = gsap.delayedCall(3.0, wishesAnimation);

    selectedLang += 1;

    if (selectedLang > wishes.length - 1){
        selectedLang = 0;
    }
}

function animate2() {
 
    var delta = clock.getDelta();

    var time = Date.now() * 0.00005;

    var hasControlsUpdated = cameraControls.update( delta );

    requestAnimationFrame( animate2 );

    renderer.autoClear = false;
    renderer.clear();
    renderer.render(backgroundScene , backgroundCamera );
    renderer.render( scene, camera );
}

function animate() {
 
    lightGroup.rotation.y = -cameraControls.azimuthAngle;

    var delta = clock.getDelta(),
    elapsedTime = clock.getElapsedTime();

    //actionSprite.update(1000 * delta);

    var time = Date.now() * 0.00005;

    var hasControlsUpdated = cameraControls.update( delta );

    requestAnimationFrame( animate );

    if ( mixer ) mixer.update( delta );
    if ( mixer2 ) mixer2.update( delta );
    // if ( mixer3 ) mixer3.update( delta );

    if (particlesFalling){
        var i = 0;

        for (i; i < particlesGroup.children.length; i ++ ) {

            var object = particlesGroup.children[ i ];

            if ( object instanceof THREE.Points ) {

                if (object.position.y < -75) {
                    object.position.y = Math.random() * 120 - 60
                    object.position.x = Math.random() * 120 - 60;
                    object.position.z = Math.random() * 120 - 60;
                }

                object.position.y += object.velocity.y / 50;
                object.animation += 0.01;
                object.position.z += (Math.sin(object.animation) * object.velocity.z) / 10;
                object.position.x += (Math.sin(object.animation) * object.velocity.x) / 10;

            }
        }
    }

    renderer.autoClear = false;
    renderer.clear();
    renderer.render(backgroundScene , backgroundCamera );
    renderer.render( scene, camera );
    
    //stats.update();
}

function overCard(){

    if (mixer && cardState == "closed" && cardReadyToInteraction){
        //document.body.className = 'pointer';

        gsap.to(mixer._actions[0], {duration:1, time:animations[1].to, delay:0, ease:Power4.easeOut, overwrite:true});
        gsap.to(mixer2._actions[0], {duration:1, time:animations[1].to, delay:0, ease:Power4.easeOut, overwrite:true});
    }

    if (cardReadyToInteraction){
        document.body.className = 'pointer';
    }
    
    cardRollOver = true;
}

function outCard(){

    if (mixer && cardState == "closed" && cardReadyToInteraction){
        //document.body.className = '';

        gsap.to(mixer._actions[0], {duration:1, time:animations[1].from, delay:0, ease:Power4.easeOut, overwrite:true});
        gsap.to(mixer2._actions[0], {duration:1, time:animations[1].from, delay:0, ease:Power4.easeOut, overwrite:true});
    }

    if (cardReadyToInteraction){
        document.body.className = '';
    }

    cardRollOver = false;
}

var hintDisplayed = 2;

function is_touch_enabled() { 
    return ( 'ontouchstart' in window ) ||  
           ( navigator.maxTouchPoints > 0 ) ||  
           ( navigator.msMaxTouchPoints > 0 ); 
} 

function moveHint(){

    hintDisplayed+=1;

    if (hintDisplayed > 3){
        if (cardState == "closed"){
            hintDisplayed = 1;
        }else{
            hintDisplayed = 2;
        }
    }

    // if (cardState == "closed"){
    //     hintDisplayed = 1;
    // }

    var touchEnabled = is_touch_enabled();

    if (!touchEnabled){
        switch(hintDisplayed){
            case 1:
                gsap.to(".icon", {duration:0.5, opacity:0, x:0, ease:Power4.easeOut, overwrite:true, onComplete: function (){

                    gsap.to(".icon_button", {duration:0, x:1, y:2, delay:0, overwrite:true});
                    gsap.fromTo(".icon_button", {duration:1.5, opacity:0.8, scale:1, transformOrigin:"32% 21%", ease:Elastic.easeIn}, {duration:0.5, scale:0.65, ease:Power1.easeIn}).repeat(-1).yoyo(true); 
                }})

                gsap.to(".icon", {duration:1, opacity:0.8, delay:0.51, ease:Power1.easeIn});
                gsap.to(".icon_description", {duration:0.2, opacity:0, ease:Power4.easeOut, overwrite:true, onComplete: function(){$(".icon_description").html("Click to open")}});
                gsap.to(".icon_description", {duration:1, opacity:0.7, delay:0.21, ease:Power1.easeIn});

                // if (cardState == "closed"){
                //     return;
                // }
            break;

            case 2:

                //gsap.to(".icon_button", {duration:0, x:1, y:2, delay:0, overwrite:true});

                gsap.to(".icon_button", {duration:0.5, opacity:0.8, scale:1, ease:Power4.easeOut});

                gsap.to(".icon_button", {duration:0.4, delay:1.3, transformOrigin:"32% 21%", scale:0.65, ease:Power4.easeOut});

                //gsap.to(".icon_button", {duration:0.5, opacity:0.8, scale:0.75, ease:Power4.easeOut, overwrite:true});

                gsap.to(".icon", {duration:0.5, opacity:0, x:0, delay:0, ease:Power4.easeOut, overwrite:true, onComplete:function(){
                    gsap.to(".icon_button", {duration:0, x:1, y:2, delay:0, overwrite:true});
                } });

                gsap.to(".icon", {duration:1, x:-10, delay:1.5, ease:Power1.easeInOut, onComplete: function(){
                    gsap.fromTo(".icon", {duration:0, x:-10, ease:Power1.easeInOut}, {duration:1, x:10, ease:Power1.easeInOut}).repeat(-1).yoyo(true); 
                }});
                gsap.to(".icon", {duration:1, opacity:0.8, delay:0.51, ease:Power1.easeIn});

                gsap.to(".icon_description", {duration:0.2, opacity:0, ease:Power4.easeOut, overwrite:true, onComplete: function(){$(".icon_description").html("Drag to rotate")}});
                gsap.to(".icon_description", {duration:1, opacity:0.7, delay:0.21, ease:Power1.easeIn});
            break;

            case 3:
                gsap.to(".icon_button", {duration:0.5, opacity:0, ease:Power4.easeOut, overwrite:true});
                
                gsap.to(".icon", {duration:0.5, opacity:0, ease:Power4.easeOut, overwrite:true});
                gsap.to(".icon", {duration:1, x:0, ease:Power4.easeOut, onComplete: function (){}});
                gsap.to(".icon", {duration:1, opacity:0.8, delay:0.51, ease:Power1.easeIn});

                gsap.to(".icon_button", {x:3.5, y:0, scale:1, opacity:0, ease:Power1.easeOut, overwrite:true});

                gsap.to(".icon_button", {duration:0.5, opacity:0.8, ease:Power1.easeOut, onComplete: function(){
                    gsap.fromTo(".icon_button", {duration:0.6, y:0, ease:Power1.easeInOut}, {duration:0.6, y:4, ease:Power1.easeInOut}).repeat(-1).yoyo(true); 
                }});

                //gsap.to(".icon_scroll", {duration:0.5, opacity:1, ease:Power1.easeOut, overwrite:true}); 
            
                gsap.to(".icon_description", {duration:0.2, opacity:0, ease:Power4.easeOut, overwrite:true, onComplete: function(){$(".icon_description").html("Scroll to zoom")}});
                gsap.to(".icon_description", {duration:1, opacity:0.7, delay:0.21, ease:Power1.easeIn});
            break;
        }
    }else{
        switch(hintDisplayed){
            case 1:

                // if (cardState != "closed"){
                //     hintDisplayed = 1;
                // }

                gsap.to(".icon_touch_2", {duration:0.5, opacity:0, ease:Power4.easeOut, overwrite:true});

                gsap.to(".icon_touch", {duration:0.5, opacity:0, x:0, ease:Power4.easeOut, overwrite:true, onComplete: function (){

                    gsap.to(".icon_touch_points", {duration:0, x:0, y:1, delay:0, overwrite:true});
                    gsap.fromTo(".icon_touch_points", {duration:1.5, opacity:0.8, scale:1.2, transformOrigin:"28% 20%", ease:Elastic.easeIn}, {duration:0.5, scale:0.65, ease:Power1.easeIn}).repeat(-1).yoyo(true); 
                }})

                gsap.to(".icon_touch", {duration:1, opacity:0.8, delay:0.51, ease:Power1.easeIn});
                gsap.to(".icon_description", {duration:0.2, opacity:0, ease:Power4.easeOut, overwrite:true, onComplete: function(){$(".icon_description").html("Tap to open")}});
                gsap.to(".icon_description", {duration:1, opacity:0.7, delay:0.21, ease:Power1.easeIn});
            break;

            case 2:

                gsap.to(".icon_touch_2", {duration:0.5, opacity:0, ease:Power4.easeOut, overwrite:true});

                gsap.to(".icon_touch_points", {duration:0.5, opacity:0.8, scale:1, ease:Power4.easeOut, overwrite:true});

                gsap.to(".icon_touch_points", {duration:0.4, delay:1.3, transformOrigin:"32% 21%", scale:0.65, ease:Power4.easeOut});

                gsap.to(".icon_touch_points", {duration:0.5, opacity:0.8, scale:1.2, ease:Power4.easeOut, overwrite:true});

                gsap.to(".icon_touch", {duration:0.5, opacity:0, x:0, delay:0, ease:Power4.easeOut, overwrite:true });

                gsap.to(".icon_touch", {duration:1, x:-10, delay:1.5, ease:Power1.easeInOut, onComplete: function(){
                    gsap.fromTo(".icon_touch", {duration:0, x:-10, ease:Power1.easeInOut}, {duration:1, x:10, ease:Power1.easeInOut}).repeat(-1).yoyo(true); 
                }});
                gsap.to(".icon_touch", {duration:1, opacity:0.8, delay:0.51, ease:Power1.easeIn});

                gsap.to(".icon_description", {duration:0.2, opacity:0, ease:Power4.easeOut, overwrite:true, onComplete: function(){$(".icon_description").html("Swipe to navigate")}});
                gsap.to(".icon_description", {duration:1, opacity:0.7, delay:0.21, ease:Power1.easeIn});
            break;

            case 3:
                gsap.to(".icon_touch_points", {duration:0.5, opacity:0, ease:Power4.easeOut, overwrite:true});
                
                //gsap.to(".icon_touch", {duration:0.5, opacity:0, ease:Power4.easeOut, overwrite:true});
                gsap.to(".icon_touch_2", {duration:1, x:0, ease:Power4.easeOut, onComplete: function (){}});
                gsap.to(".icon_touch_2", {duration:1, opacity:0.8, delay:0.51, ease:Power1.easeIn});

                gsap.to(".icon_touch_2_points", {x:0, y:0, scale:1, opacity:0, ease:Power1.easeOut, overwrite:true});

                gsap.to(".icon_touch", {duration:0.5, opacity:0, x:0, ease:Power4.easeOut, overwrite:true, onComplete: function (){

                    gsap.to(".icon_touch_2_points", {duration:0, x:0, y:1, delay:0, overwrite:true});
                    gsap.fromTo(".icon_touch_2_points", {duration:1.5, opacity:0.8, scale:1.2, transformOrigin:"28% 55%", ease:Elastic.easeIn}, {duration:0.5, scale:1  , ease:Power1.easeIn}).repeat(-1).yoyo(true); 
                }})

                //gsap.to(".icon_scroll", {duration:0.5, opacity:1, ease:Power1.easeOut, overwrite:true}); 
            
                gsap.to(".icon_description", {duration:0.2, opacity:0, ease:Power4.easeOut, overwrite:true, onComplete: function(){$(".icon_description").html("Pinch to zoom")}});
                gsap.to(".icon_description", {duration:1, opacity:0.7, delay:0.21, ease:Power1.easeIn});
            break;
        }
    }

    gsap.delayedCall(5, moveHint);
}

function readCookie(name) {
    var nameEQ = escape(name) + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return unescape(c.substring(nameEQ.length, c.length));
    };
    return null;
};
function createCookie(name, value, days) {
    var expires;
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = ";expires=" + date.toGMTString();
    } else {
        let date = new Date();
        date.setTime(date.getTime() + (10000 * 24 * 60 * 60 * 1000));
        expires = ";expires=" + date.toGMTString();
    }
    document.cookie = escape(name) + "=" + escape(value) + expires + "; path=/";
}

function startWebsite(delay){
    
    var delayIn = delay;
    

    var cookieBarSeen = readCookie('cookieBarSeen') == 'true';

    gsap.to("#logoRed", {delay:0.5, duration:0.5, autoAlpha:1, ease:Power1.easeIn})

    gsap.delayedCall(delayIn, function(){
        gsap.to("#logoRed", {duration:0.5, autoAlpha:0, ease:Power1.easeIn, onComplete:function (){
            $("#logoRed").removeClass("logo_centered");
        }})

    });

    if (!fallbackActive){
        gsap.to(objectGroupIns.position, {y: 0, duration: 4, delay:delayIn, ease:Power4.easeOut, overwrite:true});
        gsap.fromTo(objectGroupIns.rotation, {y: -100 * Math.PI/180}, {y: 0, duration: 7, delay:delayIn, ease:Power4.easeOut, overwrite:true});

        gsap.to(mixer._actions[0], {duration:0, time:animations[0].from, delay:0, ease:Power4.easeOut, overwrite:true});
        gsap.to(mixer2._actions[0], {duration:0, time:animations[0].from, delay:0, ease:Power4.easeOut, overwrite:true});

        gsap.to(mixer._actions[0], {duration:4, time:animations[0].to, delay:delayIn + 0.5, ease:Power4.easeOut});
        gsap.to(mixer2._actions[0], {duration:4, time:animations[0].to, delay:delayIn + 0.5, ease:Power4.easeOut});

        scene.add( objectGroup );
    }

    if (!cookieBarSeen){
        gsap.to(".small_cookies", {duration:1, autoAlpha:1, delay:1, ease:Power4.easeOut});
    }

    $(".close").on("click", function(){

        createCookie('cookieBarSeen', true);
        gsap.to(".small_cookies", {duration:0.3, autoAlpha:0, ease:Power4.easeOut});
    });

    // gsap.delayedCall(delayIn + 2, function(){
    //     cameraControls.enabled = true;
    //     cardReadyToInteraction = true;
    // });

    //cameraControls.enabled = true;
    //cardReadyToInteraction = true;

    //return;

    if (fallbackActive) return;

    gsap.delayedCall(delayIn + 3, function(){
        //cameraControls.enabled = true;
        //cardReadyToInteraction = true;
        
        openCard();

        cardReadyToInteraction = true;

        gsap.delayedCall(delayIn + 3.5, function(){

            // cameraControls.enabled = true;
            // cardReadyToInteraction = true;

            flatCard();

            //gsap.to(".navigation_hints", {delay:0, duration:1, autoAlpha:1, ease:Power1.easeIn});

            //moveHint();

            gsap.delayedCall(delayIn + 6, function(){

                getBackToCard();
    
                if (!fallbackActive){
                        gsap.to(".navigation_hints", {delay:0, duration:1, autoAlpha:1, ease:Power1.easeIn, onComplete:function(){
                        cameraControls.enabled = true;
                        cardReadyToInteraction = true;
                    }});
        
                    
                    moveHint();
                }
            });

        });
    });
}